:root {
    --sectionA-color: rgba(28,168,244, 1.0);
    --sectionB-color: rgba(137,82,50, 1.0);
    --sectionC-color: rgba(50,205,50, 1.0);
    --sectionD-color: rgba(218,160,131, 1.0);
    --sectionE-color: rgba(45,118,245, 1.0);
    --amazon-button-background-color: darkslategray;
    --amazon-button-color: white;
  }

$default-font: 'PoppinsRegular';
$default-font-color: darkslategray;
$default-bold-font: 'PoppinsBold';
$default-background-color: white;
$default-hover-background-color: rgba(192, 212, 235, 0.8);
$default-extract-color: black;

$article-contents-background-color: rgb(248, 246, 246);
$article-cons-background-color: rgb(227, 200, 200);
$article-font-color: black;
$article-pros-background-color: rgb(235, 254, 235);
$article-tip-box-background-color: rgb(226, 231, 233);
$article-tip-box-border-color: #ddd;
$article-product-image-title-color: slategray;

$article-list-item-name-color: darkslategray;

$contact-message-success-background-color: lightgreen;
$contact-message-error-background-color: lightcoral;

$privacy-policy-background-color: white;
$privacy-policy-contents-background-color:rgb(248, 246, 246);
$privacy-policy-font-color: black;
$privacy-policy-subheading-color: darkslategray;

$sectionA-background-color: rgba(28,168,244, 0.2);
$sectionA-font-color: lightyellow;
$sectionB-background-color: rgba(137,82,50, 0.2);
$sectionB-font-color: lightyellow;
$sectionC-background-color: rgba(50,205,50, 0.2);
$sectionC-font-color: darkslategray;
$sectionD-background-color: rgba(218,160,131, 0.2);
$sectionD-font-color: darkslategray;
$sectionE-background-color: rgba(45,118,245, 0.2);
$sectionE-font-color: lightyellow;

$footer-background-color: lightsteelblue;
$footer-link-color: darkslateblue;
$footer-copyrights-color:darkslateblue;
$privacy-policy-component-background-color: black;
$privacy-policy-component-color: white;
$related-article-heading-color: darkslategray;
$related-article-title-color: darkslategray;
$searchbar-items-list-name-color: darkslategray;

$share-buttons-facebook-background-color: #0866FF;
$share-buttons-pinterest-background-color: #BD081C;
$share-buttons-x-twitter-background-color: #000000;
$share-buttons-whatsapp-background-color: #25D366;
$share-buttons-email-background-color: #d6a400;
$share-buttons-email-color: #000000;

$top-header-desktop-menu-background-color: #ffffff;
$top-header-mobile-menu-background-color: #ffffff;
$top-header-slide-menu-background-color: #ffffff;
$top-header-slide-menu-close-button-color: #000000;